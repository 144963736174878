import { Box, Typography, useTheme, styled, Collapse } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useToggle } from "react-use";
import ArrowDown from "../../../../components/icons/arrow-down";
import useTranslate from "../../../../hooks/use-translate";
import { StoreType } from "../../constants";
import { getStoreFullHours, getStoreOpenStatus } from "../StoreCard/utils";
import HoursIconSvg from "./hours-icon.svg";

const CustomButton = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  pointer: "cursor",
  borderRadius: theme.spacing(2),
  padding: theme.spacing(0, 1),
  marginLeft: theme.spacing(-1),
  "&:hover": {
    background: theme.palette.grey[100]
  }
}));

type Props = {
  store: StoreType;
};

function StoreHours({ store }: Props) {
  const t = useTranslate();
  const theme = useTheme();
  const [expanded, toggleExpanded] = useToggle(false);

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    toggleExpanded();
  };

  const availability = getStoreOpenStatus(store, t);

  const [fullHours, setFullHours] = useState<
    {
      weekday: string;
      hours: string;
      isToday?: boolean;
    }[]
  >(null);

  // Load full hours once user expand
  useEffect(() => {
    if (!fullHours && expanded) {
      setFullHours(getStoreFullHours(store, t));
    }
  }, [expanded, store]);

  return (
    <Box display="flex" flexDirection="column">
      <CustomButton onClick={handleClick}>
        <Box display="flex" alignItems="center" mr={0.75}>
          <img src={HoursIconSvg} width={20} height={20} />
        </Box>

        <Typography
          variant="body2"
          component="span"
          style={{
            color: availability.isOpen ? "#00AF4B" : theme.palette.error.main
          }}
        >
          {availability.isOpen
            ? t("storelocator.openNow")
            : t("storelocator.close")}
          .
        </Typography>

        {expanded && (
          <ArrowDown
            style={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              pointerEvents: "none"
            }}
          />
        )}

        {availability.label && (
          <Box style={{ opacity: !expanded ? 1 : 0 }}>
            <Typography variant="body2" component="span">
              &nbsp;
              {availability.label}
            </Typography>
          </Box>
        )}

        {!expanded && (
          <ArrowDown
            style={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              pointerEvents: "none"
            }}
          />
        )}
      </CustomButton>
      <Collapse in={expanded}>
        <Box marginTop={0.5} marginLeft={3.5}>
          {fullHours &&
            fullHours.map((dayHours, i) => (
              <Typography
                variant="body2"
                color={dayHours.isToday ? "secondary" : "textSecondary"}
                component="div"
                key={i}
              >
                <Box display="flex" alignItems="center">
                  <Box minWidth={theme.spacing(12)}>{dayHours.weekday}</Box>
                  <Box>{dayHours.hours}</Box>
                </Box>
              </Typography>
            ))}
        </Box>
      </Collapse>
    </Box>
  );
}

export default React.memo(StoreHours);
