import React, { useCallback, SyntheticEvent } from "react";
import {
  styled,
  Typography,
  Box,
  Theme,
  ButtonBase,
  Button,
  useTheme
} from "@material-ui/core";
import { StoreType, StoreWithDistanceType } from "../../constants";
import CloseIcon from "../../../../components/icons/close";
import { getGoogleMapUrl, kilometersToMiles } from "./utils";
import useTranslate from "../../../../hooks/use-translate";
import { formatMultilinesText } from "../../../../helpers/markdown";
import StoreHours from "../StoreHours";
import { useInView } from "react-intersection-observer";
import AddressIconSvg from "./address-icon.svg";
import PhoneIconSvg from "./phone-icon.svg";
import DistanceIconSvg from "./distance-icon.svg";
import useCountry from "../../../../hooks/use-country";

type WrapperProps = {
  key: string;
  theme: Theme;
  isSelected?: boolean;
  isClickable?: boolean;
  isTooltip?: boolean;
};

const Wrapper = styled("div")(
  ({ theme, isSelected, isClickable, isTooltip }: WrapperProps) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
    minHeight: "220px",
    flexShrink: 0,
    background: theme.palette.common.white,
    padding: theme.spacing(2),
    marginBottom: isTooltip ? "0px" : theme.spacing(2),
    cursor: isClickable ? "pointer" : "inherit",
    borderRadius: "8px",
    overflow: "hidden",
    tapHighlightColor: "transparent",
    border: isSelected
      ? `2px solid ${theme.palette.primary.main}`
      : `2px solid transparent`,
    "& > &": {
      flex: 1
    },
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
    [theme.breakpoints.up("md")]: {
      maxWidth: "400px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2)
    }
  })
);

const CloseButton = styled(({ ...props }) => (
  <ButtonBase {...props}>
    <CloseIcon />
  </ButtonBase>
))(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(0.5),
  right: theme.spacing(0.5)
}));

const Label = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  backgroundColor: theme.palette.grey[100],
  fontSize: "1.4rem",
  fontFamily: "Roobert, Arial",
  textAlign: "center",
  borderRadius: "8px",
  marginBottom: theme.spacing(1),
  "&:not(:last-child)": {
    marginRight: theme.spacing(1)
  }
}));

/**
 * Aggressive Optimization: Only re-render StoreCard when some props changes.
 * This is to prevent re-rendering all the store cards when map is zooming are keep it smooth.
 */
const arePropsEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.isSelected === nextProps.isSelected &&
    prevProps.store?.distance === nextProps.store?.distance
  );
};

type Props = {
  store: StoreWithDistanceType;
  isSelected?: boolean;
  onClick?: (store: StoreType) => void;
  onMouseEnter?: (store: StoreType) => void;
  onMouseLeave?: (store: StoreType) => void;
  onClose?: (store: StoreType) => void;
  ctaVariant?: "website" | "delivery";
  lazyload?: boolean;
  className?: string;
  style?: React.CSSProperties;
  isTooltip?: boolean;
};

const StoreCard = React.memo(
  ({
    store,
    isSelected,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onClose,
    ctaVariant = "website",
    lazyload,
    className,
    style,
    isTooltip
  }: Props) => {
    const t = useTranslate();
    const theme = useTheme();
    const country = useCountry();

    const _onClick = useCallback(
      () => onClick && onClick(store),
      [store, onClick]
    );
    const _onMouseEnter = useCallback(
      () => onMouseEnter && onMouseEnter(store),
      [store, onMouseEnter]
    );
    const _onMouseLeave = useCallback(
      () => onMouseLeave && onMouseLeave(store),
      [store, onMouseLeave]
    );
    const stopPropagation = (event: SyntheticEvent) => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    };

    // Fade in after element enters the viewport
    const [ref, inView] = useInView({
      threshold: 0.25,
      triggerOnce: true,
      skip: !lazyload
    });

    // Distance away label
    let distanceAwayLabel: string | null = null;
    if (store.distance > 0) {
      if (country === "US") {
        distanceAwayLabel = `${kilometersToMiles(store.distance).toFixed(
          store.distance > 2 ? 0 : 1
        )} miles ${t("storelocator.away")}`;
      } else {
        distanceAwayLabel = `${store.distance.toFixed(
          store.distance > 2 ? 0 : 1
        )}km ${t("storelocator.away")}`;
      }
    }

    return (
      <Wrapper
        key={`${store.originalId}-${isSelected}`}
        className={`store-card ${className}`}
        style={style}
        onClick={_onClick}
        onMouseEnter={_onMouseEnter}
        onMouseLeave={_onMouseLeave}
        isSelected={isSelected}
        isClickable={!!onClick}
        data-store-id={store.originalId} // Used for autoscroll inside List
        data-testid="store-locator.storecard"
        ref={ref}
        isTooltip={isTooltip}
      >
        {(!lazyload || inView) && (
          <>
            <Box width="100%">
              {/* Store title */}
              <Box display="flex" flexDirection="column">
                {store.distance && (
                  <Box mb={0.5} display="flex" alignItems="center">
                    <Box
                      mr={0.25}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <img src={DistanceIconSvg} width={10} height={10} />
                    </Box>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{
                        fontWeight: 600,
                        fontSize: "1.1rem",
                        lineHeight: 1,
                        textTransform: "uppercase"
                      }}
                    >
                      {distanceAwayLabel}
                    </Typography>
                  </Box>
                )}
                <Typography variant="h6">{store.title}</Typography>
              </Box>

              {/* Seperator */}
              <Box
                width="calc(100% + 32px)"
                marginLeft={-2}
                height="1px"
                bgcolor="grey.200"
                marginTop={2}
                marginBottom={1.5}
              />

              {/* Store labels */}
              {store.labels.length > 0 && (
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  {store.labels.map(label => (
                    <Label key={label.originalId}>{label.text}</Label>
                  ))}
                </Box>
              )}

              {/* Store hours */}
              <Box marginY={0.5} minHeight={theme.spacing(3)}>
                <StoreHours store={store} />
              </Box>
            </Box>

            {/* Store address */}
            <Box display="flex" mt={0.25}>
              <Box display="flex" alignItems="center" mr={0.75}>
                <img src={AddressIconSvg} width={20} height={20} />
              </Box>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: formatMultilinesText(
                    store.addressNode.childMarkdownRemark.html
                  )
                }}
                variant="body2"
                color="textSecondary"
              />
            </Box>

            {/* Store phone */}
            {store.phone && (
              <Box display="flex" mt={0.25}>
                <Box display="flex" alignItems="center" mr={0.75}>
                  <img src={PhoneIconSvg} width={20} height={20} />
                </Box>

                <Typography variant="body2" color="textSecondary">
                  {`${t("storelocator.phone")}: `}
                  <a href={`tel:${store.phone}`}>{store.phone}</a>
                </Typography>
              </Box>
            )}

            {/* Website/Maps buttons */}
            <Box display="flex" width="100%" mt={2}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                component="a"
                href={store.url}
                target="_blank"
                rel="noreferrer noopener"
                onClick={stopPropagation}
                style={{
                  whiteSpace: "nowrap",
                  flex: 1,
                  marginRight: theme.spacing(1)
                }}
              >
                {t(
                  ctaVariant === "website"
                    ? "storelocator.visitWebsite"
                    : "storelocator.getDelivery"
                )}
              </Button>
              <Button
                component="a"
                size="small"
                href={getGoogleMapUrl(store)}
                target="_blank"
                rel="noreferrer noopener"
                onClick={stopPropagation}
                style={{
                  backgroundColor: theme.palette.grey[100],
                  whiteSpace: "nowrap",
                  flex: 1
                }}
                fullWidth
              >
                {t("storelocator.directions")}
              </Button>
            </Box>
            {onClose && <CloseButton onClick={onClose} />}
          </>
        )}
      </Wrapper>
    );
  }
);

export default React.memo(StoreCard, arePropsEqual);
