import { Box, ButtonBase, styled, Theme, Typography } from "@material-ui/core";
import React from "react";
import { StoreLabelUiType, STORES_LABELS_ICONS } from "../../constants";

export type Props = {
  labels: StoreLabelUiType[];
  onLabelClick: (label: StoreLabelUiType) => void;
};

const CustomList = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  overflowX: "visible"
}));

const CustomLabel = styled(ButtonBase)(
  ({ isSelected, theme }: { isSelected: boolean; theme: Theme }) => ({
    flexShrink: 0,
    backgroundColor: isSelected
      ? theme.palette.grey[200]
      : theme.palette.common.white,
    border: `1px solid ${
      isSelected ? theme.palette.common.black : "transparent"
    }`,
    borderRadius: "8px",
    height: "30px",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
    "&:not(:last-child)": {
      marginRight: theme.spacing(1)
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[100]
    },
    "@media (max-width: 420px)": {
      paddingRight: theme.spacing(0.75),
      paddingLeft: theme.spacing(0.75),
      "&:not(:last-child)": {
        marginRight: theme.spacing(0.75)
      },
      "& img": {
        width: "18px",
        height: "18px"
      }
    }
  })
);

const getLabelIconSvg = (label: StoreLabelUiType) => {
  return STORES_LABELS_ICONS[label.slug] || null;
};

function StoreLabel({
  label,
  onClick
}: {
  label: StoreLabelUiType;
  onClick: () => void;
}) {
  const iconSvg = getLabelIconSvg(label);
  return (
    <CustomLabel onClick={onClick} isSelected={label.isSelected}>
      {iconSvg && (
        <Box marginRight={0.5} display="flex" alignItems="center">
          <img src={iconSvg} title={label.text} width={22} height={22} />
        </Box>
      )}
      <Typography variant="body2">{label.text}</Typography>
    </CustomLabel>
  );
}

export default function StoreLabelsFilter({ labels, onLabelClick }: Props) {
  return (
    <CustomList>
      {labels.map(label => (
        <StoreLabel
          key={label.originalId}
          label={label}
          onClick={() => onLabelClick(label)}
        />
      ))}
    </CustomList>
  );
}
