import { Box, useTheme } from "@material-ui/core";
import React, { useCallback, useLayoutEffect, useState } from "react";
import MapGL, {
  FlyToInterpolator,
  MarkerProps,
  NavigationControl,
  ViewportProps
} from "react-map-gl";
import { getDeliveryAddressShortName } from "../../../../helpers/express-delivery/address";
import { DeliveryAddressType } from "../../../../types/airgraft";
import { StoreType } from "../../constants";
import StoreMarker from "./StoreMarker";
import UserGeolocationMarker from "./UserGeolocationMarker";

export type MapRefType = {
  zoomToCoordinates: ({ longitude, latitude }: MarkerProps) => void;
  setViewport: (viewportProps: ViewportProps) => void;
};

export type Props = {
  stores: StoreType[];
  selectedStore: StoreType | null;
  onSelectedStoreChange: (store: StoreType | null) => void;
  defaultViewport: ViewportProps;
  userAddress?: DeliveryAddressType;
  disableScrollZoom?: boolean;
  zoomToMarkerOffset?: { latitude?: number; longitude?: number };
  disableStoreCardPopup?: boolean;
  isMobile?: boolean;
  style?: React.CSSProperties;
};

const Map = React.forwardRef(
  (
    {
      stores,
      selectedStore,
      onSelectedStoreChange,
      defaultViewport,
      disableScrollZoom,
      disableStoreCardPopup,
      isMobile,
      style,
      zoomToMarkerOffset,
      userAddress
    }: Props,
    ref: { current: MapRefType }
  ) => {
    const theme = useTheme();

    const [viewport, setViewport] = useState<ViewportProps>(defaultViewport);
    const clearSelectedStore = useCallback(
      () => onSelectedStoreChange(null),
      []
    );

    const zoomToCoordinates = ({ longitude, latitude }: MarkerProps) => {
      return setViewport({
        ...viewport,
        latitude: latitude + (zoomToMarkerOffset?.latitude || 0),
        longitude: longitude + (zoomToMarkerOffset?.longitude || 0),
        transitionDuration: 500,
        transitionInterpolator: new FlyToInterpolator(),
        zoom: isMobile ? Math.min(6, viewport.zoom) : Math.min(8, viewport.zoom)
      });
    };

    // Expose map utils to parent using ref.
    // This will allow the root container to zoom to coords on the map after geolocation.
    ref.current = {
      zoomToCoordinates,
      setViewport
    };

    // Fly to store marker on map when user select new store on desktop
    useLayoutEffect(() => {
      if (selectedStore) {
        zoomToCoordinates(selectedStore.coordinates);
      }
    }, [selectedStore]);

    return (
      <MapGL
        {...viewport}
        width="100%"
        height="100%"
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/streets-v12"
        onViewportChange={props => {
          console.debug(props);
          setViewport(props);
        }}
        onClick={clearSelectedStore}
        style={style}
        scrollZoom={disableScrollZoom === true ? false : true}
      >
        {stores
          .filter(s => s.coordinates)
          .map(store => (
            <StoreMarker
              key={store.originalId}
              store={store}
              onClick={onSelectedStoreChange}
              isSelected={
                selectedStore && selectedStore.originalId === store.originalId
              }
              disableTooltip={isMobile}
              disableStoreCardPopup={disableStoreCardPopup}
            />
          ))}

        {userAddress?.geolocation && (
          <UserGeolocationMarker
            geolocation={userAddress.geolocation}
            tooltip={getDeliveryAddressShortName(userAddress)}
          />
        )}
        <Box
          position="absolute"
          top={theme.spacing(1)}
          right={theme.spacing(4.5)}
        >
          <NavigationControl />
        </Box>
      </MapGL>
    );
  }
);

export default Map;
