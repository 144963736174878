import { styled } from "@material-ui/core";
import React from "react";
import StoreLabelsFilter, { Props } from "./index";

const OverlayWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2.5),
  left: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    top: theme.spacing(2),
    left: 0
  }
}));

export default function MapOverlayStoreLabelsFilter(props: Props) {
  return (
    <OverlayWrapper>
      <StoreLabelsFilter {...props} />
    </OverlayWrapper>
  );
}
