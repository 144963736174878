import { Box, Container, Hidden, styled } from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import useCountry from "../../hooks/use-country";
import useIsMobile from "../../hooks/use-is-mobile";
import { useStoreLocatorAddressSelector } from "../../hooks/use-store-locator-address";
import useSubcountry from "../../hooks/use-subcountry";
import DismissableCallStoreMessage from "./components/CallStoreMessage/DismissableCallStoreMessage";
import List, { ListRefType } from "./components/List";
import { MapRefType } from "./components/Map";
import FullPageMap from "./components/Map/FullPageMap";
import MobileToggleViewButton from "./components/MobileToggleViewButton";
import StoreLabelsFilter from "./components/StoreLabelsFilter";
import MapOverlayStoreLabelsFilter from "./components/StoreLabelsFilter/MapOverlayStoreLabelsFilter";
import {
  DESKTOP_LIST_WIDTH_POURC,
  getDefaultMapViewport,
  MobileViewmodeType,
  MOBILE_DEFAULT_VIEWMODE,
  StoreType
} from "./constants";
import {
  getAllLabelsFromStores,
  getClosestStoresFromGeolocation,
  useFilteredStores,
  useLabelsFilter
} from "./utils";

const MainContainer = styled(Container)(({ theme }) => ({
  flex: 1,
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
  flexShrink: 0,
  maxHeight: "100%",
  // Max height is needed on desktop to prevent the whole page from scrolling, and only make the left list scrollable
  [theme.breakpoints.up("md")]: {
    // prettier-ignore
    maxHeight: `calc(100vh - var(--header-height) - env(safe-area-inset-top))`
  }
}));

const LeftColumn = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  flexShrink: 0,
  [theme.breakpoints.down("sm")]: {
    minWidth: "none",
    width: "100%"
  },
  [theme.breakpoints.up("md")]: {
    overflowY: "scroll", // Scroll left list on desktop
    width: `${DESKTOP_LIST_WIDTH_POURC}%`,
    minWidth: "380px",
    maxWidth: "420px",
    paddingRight: theme.spacing(2)
  }
}));

type Props = {
  stores: ReadonlyArray<StoreType>;
};

const StoreLocator = ({ stores }: Props) => {
  const country = useCountry();
  const { subcountry } = useSubcountry();

  const mapRef = useRef<MapRefType>();
  const listRef = useRef<ListRefType>();

  const subcountryStores = stores.filter(
    store => store.subcountry?.slug === subcountry
  );

  const storeLabels = getAllLabelsFromStores(subcountryStores);
  const filteredStores = useFilteredStores(subcountryStores);
  const isFilteringStores = filteredStores.length < stores.length;

  const [selectedStore, setSelectedStore] = useState<StoreType | null>(null);

  const isMobile = useIsMobile();
  const [mobileViewmode, setMobileViewmode] = useState<MobileViewmodeType>(
    MOBILE_DEFAULT_VIEWMODE
  );

  const mapDefaultViewport = getDefaultMapViewport(
    country,
    subcountry,
    isMobile
  );

  // Reset map when switching subcountry
  useEffect(() => {
    mapRef.current?.setViewport(mapDefaultViewport);
  }, [subcountry]);

  // Switch between list/map on mobile
  const toggleMobileViewmode = useCallback(() => {
    setMobileViewmode(mobileViewmode === "list" ? "map" : "list");
  }, [setMobileViewmode, mobileViewmode]);

  // Switch to map mode with selected store when clicking store card on desktop
  const handleListStoreCardClick = useCallback(
    (store: StoreType) => {
      if (isMobile) {
        return null;
      }
      setSelectedStore(store);
    },
    [setMobileViewmode, setSelectedStore, isMobile, mobileViewmode]
  );

  // Get address entered by user in the StoreLocatorAddressPanel
  const userAddress = useStoreLocatorAddressSelector(c => c.address);
  const isUserAddressInCurrentSubcountry = useStoreLocatorAddressSelector(
    c =>
      c.addressMatchingCountry === country &&
      c.addressMatchingSubcountry === subcountry
  );

  // Zoom to entered user address
  useEffect(() => {
    if (
      mapRef?.current &&
      userAddress?.geolocation &&
      isUserAddressInCurrentSubcountry
    ) {
      mapRef.current.zoomToCoordinates(userAddress.geolocation);
    }
  }, [userAddress?.geolocation, isUserAddressInCurrentSubcountry]);

  // Calculate and sort by distance between user address and each stores
  const sortedAndFilteredStores =
    userAddress && isUserAddressInCurrentSubcountry
      ? getClosestStoresFromGeolocation(userAddress.geolocation, filteredStores)
      : filteredStores;

  // Store filters
  const { filterLabels, handleLabelFilterClick } = useLabelsFilter(storeLabels);

  return (
    <>
      <MainContainer maxWidth="xl">
        {/* List */}
        <Hidden smDown={mobileViewmode !== "list"}>
          <LeftColumn>
            {/* Mobile store labels filter  */}
            <Box display={{ xs: "block", md: "none" }} marginY={2}>
              <StoreLabelsFilter
                labels={filterLabels}
                onLabelClick={handleLabelFilterClick}
              />
            </Box>

            {/* Call store message (Dismissable) */}
            <DismissableCallStoreMessage />

            {/* Store list */}
            <List
              ref={listRef}
              stores={sortedAndFilteredStores}
              selectedStore={selectedStore}
              onSelectedStoreChange={handleListStoreCardClick}
              marginTop={isMobile && isFilteringStores ? 0 : 4}
              marginBottom={{ xs: 2, md: 4 }}
              isMobile={isMobile}
            />
          </LeftColumn>
        </Hidden>

        {/* Map */}
        <Hidden smDown={mobileViewmode !== "map"}>
          <FullPageMap
            ref={mapRef}
            stores={sortedAndFilteredStores}
            selectedStore={selectedStore}
            onSelectedStoreChange={setSelectedStore}
            zoomToMarkerOffset={isMobile ? { latitude: 1 } : null}
            defaultViewport={mapDefaultViewport}
            userAddress={isUserAddressInCurrentSubcountry ? userAddress : null}
            isMobile={isMobile}
          >
            {/* Map store labels filter */}
            <MapOverlayStoreLabelsFilter
              labels={filterLabels}
              onLabelClick={handleLabelFilterClick}
            />
          </FullPageMap>
        </Hidden>

        {/* Map/View mobile floating button */}
        <MobileToggleViewButton
          viewmode={mobileViewmode}
          onClick={toggleMobileViewmode}
        />
      </MainContainer>
    </>
  );
};

export default StoreLocator;
