import { ViewportProps } from "react-map-gl";
import { CountryType } from "../../types/airgraft";

export type StoreType =
  Queries.StoreLocatorQueryDataQuery["allDatoCmsContentStore"]["nodes"][number];

export type StoreWithDistanceType = StoreType & { distance?: number };

export type StoreLabelType =
  Queries.StoreLocatorQueryDataQuery["allDatoCmsContentStore"]["nodes"][number]["labels"][number];

export type StoreLabelUiType = StoreLabelType & {
  isSelected?: boolean;
  isDynamic?: boolean;
};

interface StoreLocatorQueryData_allDatoCmsProduct_nodes_variants_variantDetail_thumbnail {
  url: string | null;
}

interface StoreLocatorQueryData_allDatoCmsProduct_nodes_variants_variantDetail {
  thumbnail: StoreLocatorQueryData_allDatoCmsProduct_nodes_variants_variantDetail_thumbnail | null;
}

interface StoreLocatorQueryData_allDatoCmsProduct_nodes_variants {
  variantDetail: StoreLocatorQueryData_allDatoCmsProduct_nodes_variants_variantDetail | null;
}

interface StoreLocatorQueryData_allDatoCmsProduct_nodes {
  slug: string | null;
  variants:
    | (StoreLocatorQueryData_allDatoCmsProduct_nodes_variants | null)[]
    | null;
}

export type OilProductType = StoreLocatorQueryData_allDatoCmsProduct_nodes;
export type OilProductUiType = OilProductType & {
  isSelected?: boolean;
};

export type GeolocationStateType = "inactive" | "active" | "loading";

export type MobileViewmodeType = "list" | "map";

export const MOBILE_DEFAULT_VIEWMODE: MobileViewmodeType = "list";

export const DESKTOP_LIST_WIDTH_POURC = 32; // % Left list width

export const getDefaultMapViewport = (
  country: CountryType,
  subcountry: string,
  isMobile: boolean
): ViewportProps => {
  const defaultProps = {
    width: null,
    height: null,
    bearing: 0,
    pitch: 0,
    altitude: 1.5,
    maxZoom: 24,
    minZoom: 0,
    maxPitch: 0,
    minPitch: 0
  };
  const defaultViewports = {
    US: {
      CA: {
        latitude: 37.06760258527157,
        longitude: -119.23111051613962,
        zoom: isMobile ? 4.5 : 5.5
      },
      OH: {
        latitude: 40.52190566888019,
        longitude: -82.88630039896898,
        zoom: isMobile ? 5.5 : 6.5
      },
      WA: {
        latitude: 47.35346403048174,
        longitude: -120.20335177875886,
        zoom: isMobile ? 5 : 6
      },
      MO: {
        latitude: 38.5383368428071,
        longitude: -92.324378435236,
        zoom: isMobile ? 5 : 6
      },
      CO: {
        latitude: 38.77859931359802,
        longitude: -105.64456635469745,
        zoom: isMobile ? 5 : 6
      },
      NM: {
        latitude: 34.30620064722328,
        longitude: -106.03541538288567,
        zoom: isMobile ? 5 : 6
      }
    },
    CA: {
      ON: {
        latitude: 44.545047035113306,
        longitude: -77.93222809816372,
        zoom: isMobile ? 4.5 : 6.5
      },
      AB: {
        latitude: 53.63330866093135,
        longitude: -114.54342088096111,
        zoom: isMobile ? 4.5 : 5.5
      }
    }
  };
  return {
    ...defaultProps,
    ...(defaultViewports[country][subcountry] || defaultViewports["US"]["CA"])
  };
};

import AccessibilityIcon from "./components/StoreLabelsFilter/icons/accessibility.svg";
import CurbsidePickupIcon from "./components/StoreLabelsFilter/icons/curbside-pickup.svg";
import DeliveryIcon from "./components/StoreLabelsFilter/icons/delivery.svg";
import OpenIcon from "./components/StoreLabelsFilter/icons/open.svg";

// slug => svg
export const STORES_LABELS_ICONS = {
  "curbside-pickup": CurbsidePickupIcon,
  accessibility: AccessibilityIcon,
  delivery: DeliveryIcon,
  open: OpenIcon
};
