import { styled, Tooltip } from "@material-ui/core";
import React from "react";
import { Marker } from "react-map-gl";
import theme from "../../../../theme/material-ui-theme";

const Icon = styled("div")(({}) => ({
  cursor: "pointer",
  color: theme.palette.primary.main
}));

const CustomToolTip = styled(Tooltip)(({}) => ({
  color: theme.palette.primary.main
}));

const CustomMarker = styled(Marker)(() => ({
  zIndex: 500,
  width: "30px",
  height: "30px",
  "& > div": {
    height: "30px"
  }
}));

type Props = {
  geolocation: {
    latitude: number;
    longitude: number;
  };
  tooltip: string;
};

const UserGeolocationMarker = React.memo(({ geolocation, tooltip }: Props) => {
  const svg = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2258_60636)">
        <circle cx="12" cy="10" r="6" fill="black" />
        <circle cx="12" cy="10" r="7" stroke="white" strokeWidth="2" />
      </g>
      <defs>
        <filter
          id="filter0_d_2258_60636"
          x="0"
          y="0"
          width="24"
          height="24"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2258_60636"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2258_60636"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );

  return (
    <CustomMarker
      latitude={geolocation.latitude}
      longitude={geolocation.longitude}
    >
      <Icon>
        <CustomToolTip
          title={tooltip}
          placement="top"
          arrow
          disableFocusListener
          disableTouchListener
          enterDelay={25}
        >
          {svg}
        </CustomToolTip>
      </Icon>
    </CustomMarker>
  );
});

export default UserGeolocationMarker;
