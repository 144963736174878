import React from "react";
import { graphql, PageProps } from "gatsby";
import { styled } from "@material-ui/core";
import { Helmet } from "react-helmet";
import BasePage from "../components/page";
import StoreLocator from "../containers/store-locator";

const Page = styled(BasePage)({
  flex: "1 1 auto",
  display: "flex",
  flexDirection: "column",
  minHeight: "calc(100vh - var(--header-height)) - var(--header-height)" // Header + Address buttton
});

const StoreLocatorPage = ({
  data
}: PageProps<Queries.StoreLocatorQueryDataQuery>) => {
  return (
    <Page {...data.datoCmsWebPage} container="fluid" modules={null}>
      <Helmet>
        <link
          href="https://api.mapbox.com/mapbox-gl-js/v1.12.0/mapbox-gl.css"
          rel="stylesheet"
        />
      </Helmet>
      <StoreLocator stores={data.allDatoCmsContentStore.nodes} />
    </Page>
  );
};

export const query = graphql`
  query StoreLocatorQueryData($locale: String!) {
    datoCmsWebPage(path: { eq: "store-locator" }, locale: { eq: $locale }) {
      ...WebPageFragment
    }
    allDatoCmsContentStore(
      filter: { locale: { eq: $locale } }
      sort: { fields: position }
    ) {
      nodes {
        ...StoreLocatorFragment
      }
    }
  }
`;

export default StoreLocatorPage;
