import { Box, Button, ButtonBase, styled } from "@material-ui/core";
import React from "react";
import useTranslate from "../../../../hooks/use-translate";
import { MobileViewmodeType } from "../../constants";
import ListViewIconSvg from "./list-view-icon.svg";
import MapViewIconSvg from "./map-view-icon.svg";

const CustomButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none"
  },
  position: "fixed",
  left: "50%",
  transform: "translateX(-50%)",
  bottom: theme.spacing(2),
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  "&:hover": {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  }
}));

type Props = {
  viewmode: MobileViewmodeType;
  onClick: () => void;
};

export default function MobileToggleViewButton({ viewmode, onClick }: Props) {
  const t = useTranslate();
  const label =
    viewmode === "map" ? t("storelocator.listView") : t("storelocator.mapView");
  const icon = viewmode === "map" ? ListViewIconSvg : MapViewIconSvg;
  return (
    <CustomButton onClick={onClick} variant="contained" size="large">
      <Box mr={1} display="flex" alignItems="center">
        <img src={icon} alt={label} width="22px" height="22px" />
      </Box>
      {label}
    </CustomButton>
  );
}
