import { DelimitedArrayParam, useQueryParam } from "use-query-params";
import useTranslate from "../../hooks/use-translate";
import { getStoreOpenStatus } from "./components/StoreCard/utils";
import {
  StoreLabelType,
  StoreLabelUiType,
  StoreType,
  StoreWithDistanceType
} from "./constants";

/**
 * Extract unique list of labels associated to a list of stores. Used for labels filter in top bar.
 */
export const getAllLabelsFromStores = (
  stores: ReadonlyArray<StoreType>
): StoreLabelType[] => {
  return Object.values(
    stores
      .reduce((accumulator, store) => {
        return [...accumulator, ...store.labels];
      }, [])
      .reduce((accumulator, label) => {
        return {
          ...accumulator,
          [label.originalId]: label
        };
      }, {})
  );
};

/**
 * Get list of stores filtered using current query parameters applied by UI filters.
 */
export const useFilteredStores = (
  stores: ReadonlyArray<StoreType>
): StoreType[] => {
  const t = useTranslate();
  let filteredStores = [...stores];

  // Apply labels filter
  const [selectedLabels] = useQueryParam("labels", DelimitedArrayParam);
  if (selectedLabels && selectedLabels.length > 0) {
    filteredStores = filteredStores.filter(store =>
      selectedLabels.every(slug => {
        if (slug === "open") {
          const { isOpen } = getStoreOpenStatus(store, t);
          return isOpen;
        }
        return store.labels.find(l => l.slug === slug);
      })
    );
  }

  // Apply Oils filter
  const [selectedProducts] = useQueryParam("products", DelimitedArrayParam);
  if (selectedProducts && selectedProducts.length > 0) {
    filteredStores = filteredStores.filter(store =>
      selectedProducts.every(productSlug =>
        store.availableProducts.find(p => p.slug === productSlug)
      )
    );
  }

  return filteredStores;
};

/**
 * Labels filter re-usable logic
 */
export const useLabelsFilter = (labels: StoreLabelType[]) => {
  const t = useTranslate();

  const [selectedLabels, setSelectedLabels] = useQueryParam(
    "labels",
    DelimitedArrayParam
  );

  const handleLabelFilterClick = (label: StoreLabelType) => {
    const values = selectedLabels || [];
    const isAlreadySelected = values.includes(label.slug);
    if (isAlreadySelected) {
      setSelectedLabels(values.filter(l => l !== label.slug));
    } else {
      setSelectedLabels([...values, label.slug]);
    }
  };

  const filterLabels: StoreLabelUiType[] = labels.map(label => ({
    ...label,
    isSelected: (selectedLabels || []).includes(label.slug)
  }));

  // Add "dynamic" label: "Only open" stores to only show stores that are currently open
  filterLabels.unshift({
    position: 0,
    originalId: "open",
    slug: "open",
    text: t("storelocator.openNow"),
    hidden: false,
    isDynamic: true,
    isSelected: (selectedLabels || []).includes("open")
  });

  return {
    filterLabels,
    handleLabelFilterClick
  };
};

/**
 * Calculate distance from a user address to each stores, and sort to get closest stores in the list
 */
export const getClosestStoresFromGeolocation = (
  geolocation: { latitude: number; longitude: number },
  stores: ReadonlyArray<StoreType>
): StoreWithDistanceType[] => {
  // Convert degrees to radians
  function degreesToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }

  // Calculate the distance between two geolocations using the Haversine formula
  function calculateDistanceBetweenStores(
    store1: { latitude: number; longitude: number },
    store2: { latitude: number; longitude: number }
  ): number {
    const earthRadiusKm = 6371;
    const dLat = degreesToRadians(store2.latitude - store1.latitude);
    const dLon = degreesToRadians(store2.longitude - store1.longitude);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degreesToRadians(store1.latitude)) *
        Math.cos(degreesToRadians(store1.latitude)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return earthRadiusKm * c;
  }

  // Calculate and assign the distance to each object
  let storesWithDistance: StoreWithDistanceType[] = stores.map(store => {
    const distance = calculateDistanceBetweenStores(geolocation, {
      latitude: store.coordinates.latitude,
      longitude: store.coordinates.longitude
    });
    return {
      ...store,
      distance
    };
  });

  // Sort the objects by distance
  storesWithDistance = storesWithDistance.sort((a, b) => {
    if (a.distance && b.distance) {
      return a.distance - b.distance;
    }
    return 0;
  });

  return storesWithDistance;
};
