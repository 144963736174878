import { Box, IconButton, styled } from "@material-ui/core";
import React from "react";
import CloseIcon from "../../../../components/icons/close";
import { useLocalStorageState } from "../../../../helpers/localstorage";
import CallStoreMessage from "./index";

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(1.5, 2),
  borderRadius: "8px",
  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(2)
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(2)
  }
}));

export default function DismissableCallStoreMessage() {
  const [isDismissed, setDismissed] = useLocalStorageState(
    "airgraft-store-locator-call-store-message-dismissed",
    false
  );

  const handleDismissClick = () => {
    setDismissed(true);
  };

  if (isDismissed) {
    return null;
  }

  return (
    <Wrapper>
      <CallStoreMessage />
      <IconButton
        size="small"
        style={{ padding: "6px", marginRight: "-10px", marginLeft: "6px" }}
      >
        <CloseIcon
          fontSize="small"
          onClick={handleDismissClick}
          style={{ fontSize: "1.6rem" }}
          color="secondary"
        />
      </IconButton>
    </Wrapper>
  );
}
