import React, { useCallback, useRef, useLayoutEffect } from "react";
import { styled } from "@material-ui/core";
import useTranslate from "../../../../hooks/use-translate";
import { StoreType, StoreWithDistanceType } from "../../constants";
import StoreCard from "../StoreCard";
import { SpacingProps } from "@material-ui/system";
import theme from "../../../../theme/material-ui-theme";

const Wrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  width: "100%",
  "& .store-card:not(:last-child)": {
    marginBottom: theme.spacing(2)
  },
  [theme.breakpoints.up("md")]: {
    paddingTop: theme.spacing(2)
  }
});

export type ListRefType = {
  scrollToStore: (
    storeId: string,
    scrollIntoViewOptions?: ScrollIntoViewOptions
  ) => void;
};

type Props = {
  stores: StoreWithDistanceType[];
  selectedStore: StoreType | null;
  onSelectedStoreChange?: (store: StoreType | null) => void;
  isMobile?: boolean;
} & SpacingProps;

const List = React.forwardRef(
  (
    { stores, selectedStore, onSelectedStoreChange, isMobile }: Props,
    ref: { current: ListRefType }
  ) => {
    const t = useTranslate();
    const wrapperRef = useRef<HTMLDivElement>(null);

    // Utils
    const clearSelectedStore = useCallback(() => {
      if (onSelectedStoreChange) onSelectedStoreChange(null);
    }, [onSelectedStoreChange]);

    const scrollToStore = (
      storeId: string,
      scrollIntoViewOptions?: ScrollIntoViewOptions
    ) => {
      if (!wrapperRef.current) {
        return null;
      }
      const storeCardRef = wrapperRef.current.querySelector(
        `div[data-store-id="${storeId}"]`
      );
      if (storeCardRef) {
        storeCardRef.scrollIntoView(
          scrollIntoViewOptions || { block: "center", behavior: "smooth" }
        );
      }
    };

    // Auto scroll to StoreCard on desktop left list when selectedStore changes
    useLayoutEffect(() => {
      if (!isMobile && selectedStore) {
        scrollToStore(selectedStore.originalId);
      }
    }, [selectedStore]);

    // Expose utils to parent using ref.
    // This will allow the root container to scroll to store cards
    ref.current = { scrollToStore };

    return (
      <Wrapper ref={wrapperRef}>
        {stores.map(store => {
          const isSelected = selectedStore
            ? store.originalId === selectedStore.originalId
            : false;
          const onCardClick = isSelected
            ? clearSelectedStore
            : onSelectedStoreChange;
          return (
            <StoreCard
              key={store.originalId}
              store={store}
              isSelected={!isMobile && isSelected}
              onClick={onCardClick}
              ctaVariant={"website"}
              lazyload
            />
          );
        })}
      </Wrapper>
    );
  }
);

export default List;
