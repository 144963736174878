import isObject from "lodash/isObject";
import moment from "moment-timezone";
import { StoreType } from "../../constants";

export function getStoreOpenStatus(
  store: StoreType,
  translate: (key: string, options?: Record<string, string>) => string
): {
  isOpen: boolean;
  label?: string;
} {
  const now = moment().tz(store.timezone);
  const weekday = moment.weekdaysShort(now.weekday()).toLowerCase();
  const storeHours = isObject(store.storeHours)
    ? store.storeHours
    : JSON.parse(store.storeHours);
  const weekdayConfig = (storeHours || {})[weekday] || false;
  if (!weekdayConfig) {
    return { isOpen: false };
  }

  const open = moment.tz(weekdayConfig.open, "HH:mm", store.timezone);
  const close = moment.tz(weekdayConfig.close, "HH:mm", store.timezone);
  const isOpen = open.isSameOrBefore(now) && close.isAfter(now);

  let label = `${open.format("h:mm a")} - ${close.format("h:mm a")}.`;
  if (!isOpen) {
    const closedForToday = close.isSameOrBefore(now);
    if (closedForToday) {
      label = null;
    } else {
      label = translate("storelocator.opensAtTime", {
        hour: open.format("h:mm a")
      });
    }
  }

  return {
    isOpen,
    label
  };
}

export function getStoreFullHours(
  store: StoreType,
  translate: (key: string, options?: Record<string, string>) => string
): { weekday: string; hours: string; isToday?: boolean }[] {
  const now = moment().tz(store.timezone);
  const days = [];
  for (let i = 0; i < 7; i++) {
    const weekdayNow = now.clone().add(i, "days");
    const weekday = moment.weekdaysShort(weekdayNow.weekday()).toLowerCase();

    const storeHours = isObject(store.storeHours)
      ? store.storeHours
      : JSON.parse(store.storeHours);

    const weekdayConfig = (storeHours || {})[weekday] || false;
    const isToday = i === 0;
    const weekdayLabel = translate(
      `${weekdayNow.format("dddd").toLowerCase()}`
    );

    if (!weekdayConfig) {
      days.push({
        isToday,
        weekday: weekdayLabel,
        hours: `Closed.`
      });
    } else {
      const open = moment.tz(weekdayConfig.open, "HH:mm", store.timezone);
      const close = moment.tz(weekdayConfig.close, "HH:mm", store.timezone);
      days.push({
        isToday,
        weekday: weekdayLabel,
        hours: `${open.format("h:mm a")} - ${close.format("h:mm a")}.`
      });
    }
  }
  return days;
}

export function getGoogleMapUrl(store: StoreType) {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    store.directions + store.title
  )}`;
}

export function kilometersToMiles(kilometers: number): number {
  const milesPerKilometer = 0.62137119;
  return kilometers * milesPerKilometer;
}
