import React from "react";
import { styled, Box } from "@material-ui/core";
import { getMapWidthToEdge } from "./utils";
import Map, { Props as MapProps, MapRefType } from "./index";

/**
 * To make the Top bar shadow visible over the map, we need to use a absolute div w/ z-index
 */
const Shadow = styled("div")({
  position: "absolute",
  top: 0,
  right: 0,
  width: "100%",
  height: "100%",
  zIndex: 100,
  pointerEvents: "none"
});

const AbsoluteWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  height: "100%",
  overflow: "hidden",
  width: `calc(100% + ${theme.spacing(8)}px)`,
  [theme.breakpoints.up("xl")]: {
    width: getMapWidthToEdge(`${theme.breakpoints.values.xl}px`)
  },
  // Make map edge to edge on mobile sm & xs, ignoring container padding
  [theme.breakpoints.down("sm")]: {
    width: `calc(100% + ${theme.spacing(6)}px)`,
    left: `-${theme.spacing(3)}px`,
    // Hide mapbox UI controls on mobile
    "& .mapboxgl-ctrl-group": {
      display: "none"
    }
  },
  [theme.breakpoints.down("xs")]: {
    width: `calc(100% + ${theme.spacing(4)}px)`,
    left: `-${theme.spacing(2)}px`
  }
}));

const FullPageMap = React.forwardRef(
  (
    props: MapProps & { children?: JSX.Element },
    ref: { current: MapRefType }
  ) => {
    return (
      <Box position="relative" flex="1" data-testid="store-locator.map">
        <AbsoluteWrapper>
          <Shadow />
          <Map ref={ref} {...props} />
        </AbsoluteWrapper>
        {props.children}
      </Box>
    );
  }
);

export default FullPageMap;
